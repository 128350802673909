import React from 'react';
import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import backofficeRoutes from './backoffice.routes';
import Preloader from '../components/preloader/preloader.component';

const DBYShomePage = React.lazy(() => import('../../dbys/pages/index'));
const CustomerCardOnlinePage = React.lazy(() => import('../../dbys/pages/customer-card-online.page'));
const CustomerCardOfflinePage = React.lazy(() => import('../../dbys/pages/customer-card-offline.page'));
const TicketPoolPage = React.lazy(() => import('../../dbys/pages/ticket-pool.page'));
const BackOfficePage = React.lazy(() => import('../../dbys/pages/back-office/back-office.page'));

const dbysRoutes: RouteObject[] = [
  {
    path: '/dbys',
    element: (
      <Suspense fallback={<Preloader />}>
        <DBYShomePage />
      </Suspense>
    ),
    children: [
      {
        path: 'customer-search',
        element: (
          <Suspense fallback={<Preloader />}>
            <CustomerCardOnlinePage />
          </Suspense>
        ),
      },
      {
        path: ':customerPhoneNumber',
        element: (
          <Suspense fallback={<Preloader />}>
            <CustomerCardOnlinePage />
          </Suspense>
        ),
      },
      {
        path: 'customer-card-offline/:vkn/:issueID',
        element: (
          <Suspense fallback={<Preloader />}>
            <CustomerCardOfflinePage />
          </Suspense>
        ),
      },
      {
        path: 'ticket-pool',
        element: (
          <Suspense fallback={<Preloader />}>
            <TicketPoolPage />
          </Suspense>
        ),
      },
      {
        path: 'back-office',
        element: (
          <Suspense fallback={<Preloader />}>
            <BackOfficePage />
          </Suspense>
        ),
        children: [...backofficeRoutes],
      },
    ],
  },
];

export default dbysRoutes;
