import React from 'react';
import Keycloak from 'keycloak-js';
import keycloakConfig from './keycloak.config';
import KeycloakContext from './keycloak.context';
export const keycloakInstance = new Keycloak(keycloakConfig);
type Props = {
  children: React.ReactNode;
};
const KeycloakProvider: React.FC<Props> = ({ children }) => {
  const [keycloak, setKeycloak] = React.useState<Keycloak | undefined>(undefined);
  const [authenticated, setAuthenticated] = React.useState(false);
  React.useEffect(() => {
    keycloakInstance
      .init({ onLoad: 'check-sso', checkLoginIframe: false })
      .then((authenticated) => {
        setKeycloak(keycloakInstance);
        setAuthenticated(authenticated);
        if (!authenticated) {
          keycloakInstance.login();
        }
      })
      .catch((error) => {
        console.error('keycloak init error:', error);
      });
  }, []);

  const logout = () => {
    keycloak?.logout({ redirectUri: window.location.origin });
  };

  return (
    <KeycloakContext.Provider value={{ keycloak, authenticated, logout }}>
      {authenticated ? children : null}
    </KeycloakContext.Provider>
  );
};

export default React.memo(KeycloakProvider);
