import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';
import TIssueList from '../../models/issue-list.type';
import TGetCreateIssue from '../../models/get-create-issue.type';
import TCreateIssue from '../../models/create-issue.type';
import TUpdateIssue from '../../models/update-issue.type';
import DBYSJiraServices from '../../services/jira-services';
import TContactHistory from '../../models/contact-history.type';

namespace IssueListStore {
  export const getIssueList = createAsyncThunk(
    'setIssueList',
    async ({ startCount, endCount, logData }: { startCount: number; endCount: number; logData: TJIRAPayload }) => {
      const response = await DBYSJiraServices.getIssueList({
        startCount: startCount,
        endCount: endCount,
        logData: logData,
      });
      if (!response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'error' })
        );
      }
      return response.data;
    }
  );

  export const getCreateIssue = createAsyncThunk(
    'company/getCreateIssue',
    async (issue: TJIRAPayload<TGetCreateIssue>) => {
      const response = await DBYSJiraServices.getCreateIssue(issue);
      if (!response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'error' })
        );
      }
      return response.data;
    }
  );

  export const updateIssue = createAsyncThunk(
    'company/updateIssue',
    async ({ issue, issueId }: { issue: TUpdateIssue; issueId: string }) => {
      const response = await DBYSJiraServices.updateIssue(issue, issueId);
      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'success' })
        );
      }
      return response.data;
    }
  );

  export const updateIssueStatus = createAsyncThunk(
    'company/updateIssueStatus',
    async (issueId: { issueId: string }) => {
      const response = await DBYSJiraServices.getUpdateStatus(issueId);
      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'success' })
        );
      }
      return response.data;
    }
  );

  type TIssueListSliceType = {
    issueList: TIssueList | undefined;
    createIssue: TCreateIssue | undefined;
    issueListPending: boolean;
    issuelistFulfilled: boolean;
  };

  const initialState: TIssueListSliceType = {
    issueList: undefined,
    createIssue: undefined,
    issuelistFulfilled: false,
    issueListPending: false,
  };

  const issueListSlice = createSlice({
    name: 'issueList',
    initialState,
    reducers: {
      reset: () => initialState,
      setIssueList(state, action: PayloadAction<TIssueList>) {
        state.issueList = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getIssueList.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.issueList = action.payload.data;
        }
        state.issuelistFulfilled = true;
        state.issueListPending = false;
      });

      builder.addCase(getIssueList.pending, (state) => {
        state.issuelistFulfilled = false;
        state.issueListPending = true;
      });

      builder.addCase(getCreateIssue.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.createIssue = action.payload.data;
        }
      });
    },
  });

  export const actions = issueListSlice.actions;
  export const reducer = issueListSlice.reducer;
  export const select = {
    issueList: (state: AppStore.RootState) => state.dbys.issueList.issueList,
    createIssue: (state: AppStore.RootState) => state.dbys.issueList.createIssue,
    issueListPending: (state: AppStore.RootState) => state.dbys.issueList.issueListPending,
    issuelistFulfilled: (state: AppStore.RootState) => state.dbys.issueList.issuelistFulfilled,
  };
}

export default IssueListStore;
