import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSJiraServices from '../../services/jira-services';

namespace IssueFileThunks {
  export const getAttachmentList = createAsyncThunk('getAttachmentList', async (issueId: string) => {
    const response = await DBYSJiraServices.getAttachmentList(issueId);
    return response.data;
  });

  export const getAttachmen = createAsyncThunk('getAttachmen', async (attachmentId: string) => {
    const response = await DBYSJiraServices.getAttachmen(attachmentId);
    return response.data;
  });
}

export default IssueFileThunks;
