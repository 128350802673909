import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import Utils from '../../../utils';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';
import TBackOfficeUpdateUser from '../../models/backoffice/backoffice-update-user.type';

namespace BackOfficeUserWithVknThunks {
  export const getUsersWithVkn = createAsyncThunk(
    'backoffice/getUsersWithVkn',
    async ({
      username,
      identityNumber,
      projectName,
    }: {
      username: string;
      identityNumber: string;
      projectName: string;
    }) => {
      const response = await DBYSBackofficeServices.getcompanyuserswithcompanyvkn({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: { identityNumber },
        project: projectName,
        transactionName: 'Müşteri Bilgileri Güncelleme',
      });
      return response.data;
    }
  );

  export const updateUser = createAsyncThunk('backoffice/updateUser', async (payload: TBackOfficeUpdateUser) => {
    const response = await DBYSBackofficeServices.updatecompanyuserswithcompanyvknandid({
      corelationId: payload.corelationId,
      executedByOutSourceUser: payload.executedByOutSourceUser || '',
      supportRequestCode: payload.supportRequestCode,
      changeValues: payload.changeValues,
      data: {
        companyVkn: payload.data.companyVkn,
        userId: payload.data.userId,
        name: payload.data.name,
        surname: payload.data.surname,
        email: payload.data.email,
        phoneNumber: payload.data.phoneNumber,
      },
    });
    if (response.data.status) {
      AppStore.store.dispatch(
        ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'success' })
      );
    }
    return response.data;
  });
}

export default BackOfficeUserWithVknThunks;
