import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';
import DBYSCRMServices from '../../services/crm-services';

namespace BackOfficeUpdateWorkOrdersThunks {
  export const getWorkOrder = createAsyncThunk(
    'backoffice/workorders/getInfo',
    async (payload: TGetLogParameters<{ worOrderId: string }>) => {
      const response = await DBYSBackofficeServices.getworkorderinfoIE(payload);
      return response.data;
    }
  );
  export const getWorkOrderStatusList = createAsyncThunk('backoffice/workorders/getWorkOrderStatusList', async () => {
    const response = await DBYSCRMServices.getworkorderstatusparameterlist();
    return response.data;
  });
  export const updateWorkOrder = createAsyncThunk(
    'backoffice/workorders/updateInfo',
    async ({
      command,
      fieldName,
      postData,
      value,
    }: {
      postData: TUpdateLogParameters<{ workOrderId: string }>;
      value: string;
      command: string;
      fieldName: string;
    }) => {
      const response = await DBYSBackofficeServices.updateworkorderinfoIE({
        command,
        fieldName,
        postData,
        value,
      });
      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'success' })
        );
      }
      return response.data;
    }
  );
}

export default BackOfficeUpdateWorkOrdersThunks;
