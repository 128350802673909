import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TOfflineIssue from '../../models/offline-issue';

namespace OfflineIssueStore {
  type TOfflineCustomerSlice = {
    selectedOfflineIssue: TOfflineIssue | undefined;
  };

  export const initialState: TOfflineCustomerSlice = {
    selectedOfflineIssue: undefined,
  };

  const offlineCustomerSlice = createSlice({
    name: 'offlineIssue',
    initialState,
    reducers: {
      setSelectedOfflineIssue(state, action: PayloadAction<TOfflineIssue | undefined>) {
        state.selectedOfflineIssue = action.payload;
      },
    },
  });

  export const actions = offlineCustomerSlice.actions;
  export const reducer = offlineCustomerSlice.reducer;

  export const select = {
    selectedOfflineIssue: (state: AppStore.RootState) => state.dbys.offlineIssue.selectedOfflineIssue,
  };
}

export default OfflineIssueStore;
