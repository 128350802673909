import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TBackOfficeWorkOrderDetailsLikeab from '../../models/backoffice/backoffice-work-order-details-likeab.type';
import BackOfficeUpdateAomProductNameThunks from '../async-thunks/backoffice-update-aom-product-name.thunks';

namespace BackOfficeUpdateAomProductNameStore {
    type TBackOfficeUpdateAomProductNameSlice = {
        getAomProductName: TBackOfficeWorkOrderDetailsLikeab | undefined;
    };

    export const initialState: TBackOfficeUpdateAomProductNameSlice = {
        getAomProductName: undefined,
    };

    const backOfficeUpdateAomProductNameSlice = createSlice({
        name: 'backOfficeUpdateAomProductNameSlice',
        initialState,
        reducers: {
            reset: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(BackOfficeUpdateAomProductNameThunks.getworkorderdetailslikeab.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.getAomProductName = action.payload.data;
                }
            });
        },
    });

    export const actions = backOfficeUpdateAomProductNameSlice.actions;
    export const reducer = backOfficeUpdateAomProductNameSlice.reducer;
    export const select = {
        getAomProductName: (state: AppStore.RootState) => state.dbys.backOfficeUpdateAomProductName.getAomProductName,
    };
}

export default BackOfficeUpdateAomProductNameStore;
