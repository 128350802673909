import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TBackOfiiceWorkOrderE from '../../models/backoffice/backoffice-work-order-e.type';
import BackOfficeGetWorkOrderEThunks from '../async-thunks/backoffice-update-work-order-e.thunks';
import TBackOfficeCustomSubDnsRolesUsers from '../../models/backoffice/backoffice-custom-sub-dbs-roles-users.type';

namespace BackOfficeUpdateWorkOrderEStore {
    type TBackOfficeUpdateWorkOrderESlice = {
        workOrderE: TBackOfiiceWorkOrderE | undefined;
        userList: TBackOfficeCustomSubDnsRolesUsers | undefined;
    };

    export const initialState: TBackOfficeUpdateWorkOrderESlice = {
        workOrderE: undefined,
        userList: undefined,
    };

    const backOfficeUpdateWorkOrderESlice = createSlice({
        name: 'backOfficeUpdateWorkOrderE',
        initialState,
        reducers: {
            reset: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(BackOfficeGetWorkOrderEThunks.getWorkOrderE.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.workOrderE = action.payload.data;
                }
            });
            builder.addCase(BackOfficeGetWorkOrderEThunks.getcustomsubdnsrolesusers.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.userList = action.payload.data;
                }
            });
        },
    });

    export const actions = backOfficeUpdateWorkOrderESlice.actions;
    export const reducer = backOfficeUpdateWorkOrderESlice.reducer;
    export const select = {
        getWorkOrderE: (state: AppStore.RootState) => state.dbys.backOfficeUpdateWorkOrderE.workOrderE,
        getUserList: (state: AppStore.RootState) => state.dbys.backOfficeUpdateWorkOrderE.userList,
    };
}

export default BackOfficeUpdateWorkOrderEStore;
