import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import Utils from '../../../utils';

namespace BackOfficeGetWorkOrderEThunks {
  export const getWorkOrderE = createAsyncThunk(
    'backoffice/getWorkOrderE',
    async ({
      projectName,
      pageName,
      username,
      workOrderId,
    }: {
      projectName: string;
      pageName: string;
      username: string;
      workOrderId: string;
    }) => {
      const response = await DBYSBackofficeServices.getworkorderseinfo({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        project: projectName,
        transactionName: pageName,
        data: { worOrderId: workOrderId },
      });
      return response.data;
    }
  );
  export const getcustomsubdnsrolesusers = createAsyncThunk(
    'backoffice/getcustomsubdnsrolesusers',
    async ({ username }: { corelationId: string; username: string }) => {
      const response = await DBYSBackofficeServices.getcustomsubdnsrolesusers({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: null,
      });
      return response.data;
    }
  );
}

export default BackOfficeGetWorkOrderEThunks;
