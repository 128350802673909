import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TBackOfiiceWorkOrderTt from '../../models/backoffice/backoffice-work-order-tt.type';
import BackOfficeGetWorkOrderTtThunks from '../async-thunks/backoffice-update-work-order-tt.thunks';

namespace BackOfficeUpdateWorkOrderTtStore {
    type TBackOfficeUpdateWorkOrderTtSlice = {
        workOrderTt: TBackOfiiceWorkOrderTt | undefined;
    };

    export const initialState: TBackOfficeUpdateWorkOrderTtSlice = {
        workOrderTt: undefined,
    };

    const backOfficeUpdateWorkOrderTtSlice = createSlice({
        name: 'backOfficeUpdateWorkOrderTt',
        initialState,
        reducers: {
            reset: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(BackOfficeGetWorkOrderTtThunks.getWorkOrderTt.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.workOrderTt = action.payload.data;
                }
            });
        },
    });

    export const actions = backOfficeUpdateWorkOrderTtSlice.actions;
    export const reducer = backOfficeUpdateWorkOrderTtSlice.reducer;
    export const select = {
        getWorkOrderTt: (state: AppStore.RootState) => state.dbys.backOfficeUpdateWorkOrderTt.workOrderTt,
    };
}

export default BackOfficeUpdateWorkOrderTtStore;
