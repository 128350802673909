import onlyDigitRegex from '../contants/only-digit.regex';
import vknRegexForInput from '../contants/vkn-for-input.regex';
import vknRegex from '../contants/vkn.regex';

namespace Utils {
  export const isOnlyDigit = (value: string) => onlyDigitRegex.test(value);
  export const isVKN = (value: string, isForInput = false) => {
    if (isForInput) {
      return vknRegexForInput.test(value);
    }
    return vknRegex.test(value);
  };
  export const getUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  export const calculateSLA = (remaningTime: number) => {
    const calculatedHours = Math.floor(remaningTime / 3600000);
    const calculatedMinutes = Math.floor((remaningTime % 3600000) / 60000);

    const positiveMinutes = calculatedMinutes < 0 ? -calculatedMinutes : calculatedMinutes;

    return {
      hours: calculatedHours,
      minutes: positiveMinutes,
    };
  };

  export const getType = (obj: any) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
}

export default Utils;
