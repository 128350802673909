import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '..';
import ToastMessageTypeList from '../../contants/toast-message-type-list.enum';
import Utils from '../../utils';

namespace ToastMessageStore {
  export type TToastMessageTypes = keyof typeof ToastMessageTypeList;

  export type TToastrMessage = {
    id: string;
    text: string;
    type: TToastMessageTypes;
  };

  type TToastMessageStore = {
    messageList: TToastrMessage[];
  };

  const initialState: TToastMessageStore = {
    messageList: [],
  };

  const toastMessageSlice = createSlice({
    name: 'toastMessage',
    initialState,
    reducers: {
      addToastMessage: (state: TToastMessageStore, action: PayloadAction<Omit<TToastrMessage, 'id'>>) => {
        const newMessage: TToastrMessage = {
          ...action.payload,
          id: Utils.getUUID(),
        };
        state.messageList.push(newMessage);
      },
      deleteToastMessage: (state: TToastMessageStore, action: PayloadAction<string>) => {
        state.messageList = state.messageList.filter((message) => message.id !== action.payload);
      },
    },
  });

  export const reducer = toastMessageSlice.reducer;
  export const actions = toastMessageSlice.actions;
  export const select = {
    messageList: (state: AppStore.RootState) => state.toastMessage.messageList,
  };
}

export default ToastMessageStore;
