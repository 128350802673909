import { RouteObject } from 'react-router-dom';
import { Suspense } from 'react';
import React from 'react';
import Preloader from '../components/preloader/preloader.component';

const BackofficeEntryPage = React.lazy(() => import('../pages/back-office/backoffice-entry.page'));
const BackOfficeUpdateCompanyPage = React.lazy(() => import('../pages/back-office/back-office-update-company.page'));
const BackOfficeUpdateUserPage = React.lazy(() => import('../pages/back-office/back-office-update-user.page'));
const BackofficeInkOperationsPage = React.lazy(() => import('../pages/back-office/back-office-ink-operations.page'));
const BackOfficeInkOperations2Page = React.lazy(() => import('../pages/back-office/back-office-ink-operations-2.page'));
const BackOfficeUpdateWorkOrdersPage = React.lazy(
  () => import('../pages/back-office/back-office-update-work-orders.page')
);
const BackofficeUpdateShippingOperations = React.lazy(
  () => import('../pages/back-office/back-office-update-shipping-operations.page')
);
const BackofficeInventoryMovenments = React.lazy(
  () => import('../pages/back-office/back-office-inventory-movenments.page')
);
const BackoffAcceptedPackingApprovalSample = React.lazy(
  () => import('../pages/back-office/back-office-accepted-packing-approval-sample.page')
);
const BackofficePackingApprovalCenter = React.lazy(
  () => import('../pages/back-office/back-office-packing-approval-center.page')
);

const backofficeRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackofficeEntryPage />
      </Suspense>
    ),
  },
  {
    path: 'update-company',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackOfficeUpdateCompanyPage />
      </Suspense>
    ),
  },
  {
    path: 'update-user',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackOfficeUpdateUserPage />
      </Suspense>
    ),
  },
  {
    path: 'ink-operations',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackofficeInkOperationsPage />
      </Suspense>
    ),
  },
  {
    path: 'ink-operations-2',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackOfficeInkOperations2Page />
      </Suspense>
    ),
  },
  {
    path: 'update-work-orders',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackOfficeUpdateWorkOrdersPage />
      </Suspense>
    ),
  },
  {
    path: 'update-shipping-operations',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackofficeUpdateShippingOperations />
      </Suspense>
    ),
  },
  {
    path: 'inventory-movenments',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackofficeInventoryMovenments />
      </Suspense>
    ),
  },
  {
    path: 'accepted-packing-approval-sample',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackoffAcceptedPackingApprovalSample />
      </Suspense>
    ),
  },
  {
    path: 'packing-approval-center',
    element: (
      <Suspense fallback={<Preloader />}>
        <BackofficePackingApprovalCenter />
      </Suspense>
    ),
  },
];

export default backofficeRoutes;
