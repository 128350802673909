import { createSlice } from '@reduxjs/toolkit';
import TAgentCompanyInfo from '../../models/agent-company-info.type';
import AppStore from '../../../store';

namespace BackOfficeUpdateCompanyStore {
  type TBackOfficeUpdateCompanySlice = {
    selectedCompany: TAgentCompanyInfo | undefined;
  };

  export const initialState: TBackOfficeUpdateCompanySlice = {
    selectedCompany: undefined,
  };

  const backOfficeUpdateCompanySlice = createSlice({
    name: 'backOfficeUpdateCompany',
    initialState,
    reducers: {
      setSelectedCompany: (state, action: { payload: TAgentCompanyInfo }) => {
        state.selectedCompany = action.payload;
      },
      reset: () => initialState,
    },
    extraReducers: (builder) => {
      /* builder.addCase(BackOfficeUpdateCompanyThunks.getCompanyInfoByVKN.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.selectedCompany = action.payload.data[0];
        }
      }); */
    },
  });

  export const actions = backOfficeUpdateCompanySlice.actions;
  export const reducer = backOfficeUpdateCompanySlice.reducer;
  export const select = {
    getSelectedCompany: (state: AppStore.RootState) => state.dbys.backofficeUpdateCompany.selectedCompany,
  };
}

export default BackOfficeUpdateCompanyStore;
