import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';

namespace BackOfficeUpdateAomProductNameThunks {
  export const getworkorderdetailslikeab = createAsyncThunk(
    'backoffice/getworkorderdetailslikeab',
    async (
      payload: TGetLogParameters<{
        workOrderId: string;
      }>
    ) => {
      try {
        const response = await DBYSBackofficeServices.getworkorderdetailslikeab(payload);
        return response.data;
      } catch (error) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'error',
            text: 'An error occurred while fetching work order details.',
          })
        );
        throw error;
      }
    }
  );

  export const updateAomProductName = createAsyncThunk(
    'backoffice/updateAomProductName',
    async (
      payload: TUpdateLogParameters<{
        workOrderId: string;
        productName: string;
      }>
    ) => {
      const response = await DBYSBackofficeServices.updateaomproductname({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode,
        oldValue: payload.oldValue,
        newValue: payload.newValue,
        data: {
          workOrderId: payload.data.workOrderId,
          productName: payload.data.productName,
        },
      });

      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }

      return response.data;
    }
  );
}

export default BackOfficeUpdateAomProductNameThunks;
