import { createBrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import dbysRoutes from '../dbys/routes';
import Preloader from '../dbys/components/preloader/preloader.component';

const AppPage = React.lazy(() => import('../App'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<Preloader />}>
        <AppPage />
      </Suspense>
    ),
    children: [...dbysRoutes],
  },
]);

export default router;
