import React from 'react';
import Keycloak from 'keycloak-js';

const KeycloakContext = React.createContext<{
  keycloak: Keycloak | undefined;
  authenticated: boolean;
  logout: () => void;
}>({
  keycloak: undefined,
  authenticated: false,
  logout: () => {},
});
export default KeycloakContext;

const useKeycloakContext = () => React.useContext(KeycloakContext);
export { useKeycloakContext };
