import { createAsyncThunk } from '@reduxjs/toolkit';
import Utils from '../../../utils';
import DBYSJiraServices from '../../services/jira-services';
import DBYSCRMServices from '../../services/crm-services';

namespace AgentOnlineCustomerThunks {
  export const getCompanyInfoByPhoneNumber = createAsyncThunk(
    'company/getByPhoneNumber',
    async ({ username, phoneNumber, projectName }: { username: string; phoneNumber: string; projectName: string }) => {
      const response = await DBYSCRMServices.getCompanyInfo({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: { phoneNumber },
        project: projectName,
        transactionName: 'Müşteri Kartı',
      });
      return response.data;
    }
  );
  export const getallcompanyinfo = createAsyncThunk(
    'company/getallcompanyinfo',
    async ({
      username,
      companyNameOrVknSearch,
      projectName,
    }: {
      username: string;
      companyNameOrVknSearch: string;
      projectName: string;
    }) => {
      const response = await DBYSCRMServices.getallcompanyinfo({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: { companyNameOrVknSearch },
        project: projectName,
        transactionName: 'Müşteri Kartı',
        referansId: companyNameOrVknSearch,
        stakeHolderId: '-',
        stakeHolderName: '-',
        stakeHolderSegment: '-',
        transactionArea: '-',
        transactionType: 'VKN ya da firma adına göre arama',
      });
      return response.data;
    }
  );
  export const getCompanyInfoByVKN = createAsyncThunk(
    'company/getByVKN',
    async ({
      username,
      identityNumber,
      projectName,
    }: {
      username: string;
      identityNumber: string;
      projectName: string;
    }) => {
      const response = await DBYSCRMServices.getCompanyInfo({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: { identityNumber },
        project: projectName,
        transactionName: 'Müşteri Kartı Online',
        referansId: identityNumber,
        referansName: 'identityNumber',
        transactionType: 'VKN ye göre arama',
        stakeHolderName: '-',
        stakeHolderId: '-',
        stakeHolderSegment: '-',
        transactionArea: '-',
      });
      return response.data;
    }
  );
  export const getTicketTypeList = createAsyncThunk('company/getTicketTypeList', async () => {
    const response = await DBYSCRMServices.getTicketTypeList({
      project: 'dbys',
    });
    return response.data;
  });
  export const getReasonRequest = createAsyncThunk('company/getReasonRequest', async (requestTypeId: number) => {
    const response = await DBYSCRMServices.getReasonRequest({
      project: 'dbys',
      requestTypeId: requestTypeId,
    });
    return response.data;
  });

  export const getSubReasonRequest = createAsyncThunk(
    'company/getSubReasonRequest',
    async (reasonForRequestId: number) => {
      const response = await DBYSCRMServices.getSubReasonRequest({
        project: 'dbys',
        reasonForRequestId: reasonForRequestId,
      });
      return response.data;
    }
  );

  export const getPriority = createAsyncThunk('company/getPriority', async (project: string) => {
    const response = await DBYSCRMServices.getpriority({
      project: project,
    });
    return response.data;
  });

  export const getStolkholderType = createAsyncThunk('company/getStolkholderType', async (project: string) => {
    const response = await DBYSCRMServices.getStolkholderType({
      project: project,
    });
    return response.data;
  });
  export const getContactHistory = createAsyncThunk('company/getContactHistory', async (stakeHolderId: string) => {
    const response = await DBYSCRMServices.getContactHistory({
      pageName: 1,
      stakeHolderId,
    });
    return response.data;
  });

  export const GetLastFiveIssueByVKN = createAsyncThunk(
    'company/getJiraIssue',
    async ({ logData, taxNumber }: { taxNumber: string; logData: TJIRAPayload }) => {
      const response = await DBYSJiraServices.getLastFiveIssueByVKN({
        taxNumber,
        logData,
      });
      return response.data;
    }
  );
}

export default AgentOnlineCustomerThunks;
