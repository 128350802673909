import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TBackOfficeInvShipmentDetails from '../../models/backoffice/backoffice-inv-shipment-details.type';
import BackOfficeUpdateInvShipmentThunks from '../async-thunks/backoffice-update-inv-shipment.thunks';

namespace BackOfficeUpdateInvShipmentStore {
    type TBackOfficeUpdateInvShipmentSlice = {
        getInvShipment: TBackOfficeInvShipmentDetails | undefined;
    };

    export const initialState: TBackOfficeUpdateInvShipmentSlice = {
        getInvShipment: undefined,
    };

    const backOfficeUpdateInvShipmentSlice = createSlice({
        name: 'backOfficeUpdateInvShipmentSlice',
        initialState,
        reducers: {
            reset: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(BackOfficeUpdateInvShipmentThunks.getInvShipment.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.getInvShipment = action.payload.data;
                }
            });
        },
    });

    export const actions = backOfficeUpdateInvShipmentSlice.actions;
    export const reducer = backOfficeUpdateInvShipmentSlice.reducer;
    export const select = {
        getInvShipment: (state: AppStore.RootState) => state.dbys.backOfficeUpdateInvShipment.getInvShipment,
    };
}

export default BackOfficeUpdateInvShipmentStore;
