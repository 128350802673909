import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import Utils from '../../../utils';
import TBackOfficeUpdateStockAmount from '../../models/backoffice/backoffice-update-stock-amount.type';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';

namespace BackOfficeGetPackpageAmountThunks {
  export const getPackpageAmount = createAsyncThunk(
    'backoffice/getPackpageAmount',
    async ({ packPageCode, username }: { packPageCode: string; username: string }) => {
      const response = await DBYSBackofficeServices.getinvstockamountbypackagecode({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: { packageCode: packPageCode },
      });
      return response.data;
    }
  );

  export const updateinvstockamountbypackagecode = createAsyncThunk(
    'backoffice/updateUser',
    async (payload: TBackOfficeUpdateStockAmount) => {
      const response = await DBYSBackofficeServices.updateinvstockamountbypackagecode({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode,
        oldValue: payload.oldValue,
        newValue: payload.newValue,
        data: {
          amount: payload.data.amount,
          packageCode: payload.data.packageCode,
        },
      });
      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'success' })
        );
      }
      return response.data;
    }
  );
}

export default BackOfficeGetPackpageAmountThunks;
