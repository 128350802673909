import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TGetIssueByID from '../../models/get-issue-by-id.type';
import GetIssueByIdThunks from '../async-thunks/get-isuue-by-id.thunks';

namespace GetIssueByIdStore {
    type TGetIssueByIdSlice = {
        getIssueById: TGetIssueByID | undefined;
    };

    export const initialState: TGetIssueByIdSlice = {
        getIssueById: undefined,
    };

    const GetIssueByIdSlice = createSlice({
        name: 'GetIssueById',
        initialState,
        reducers: {
            reset: () => initialState,
        },

        extraReducers: (builder) => {
            builder.addCase(GetIssueByIdThunks.getIssueById.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.getIssueById = action.payload.data;
                }
            })
        }
    });

    export const actions = GetIssueByIdSlice.actions;
    export const reducer = GetIssueByIdSlice.reducer;
    export const select = {
        getIssueById: (state: AppStore.RootState) => state.dbys.getIssueById.getIssueById
    };

}

export default GetIssueByIdStore;
