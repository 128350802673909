import http from '../../http';
import TAgentCompanyInfo from '../models/agent-company-info.type';
import TBackOfiiceAomProductName from '../models/backoffice/backoffice-aom-product-name.type';
import TBackOfficeCustomSubDnsRolesUsers from '../models/backoffice/backoffice-custom-sub-dbs-roles-users.type';
import TBackOfficeGetPackpageAmount from '../models/backoffice/backoffice-get-packpage-amount.type';
import TBackOfficeInvShipmentDetails from '../models/backoffice/backoffice-inv-shipment-details.type';
import TBackofficeInventory from '../models/backoffice/backoffice-inventory.type';
import TBackOfficeUpdateCompanyInfo from '../models/backoffice/backoffice-update-company-info.type';
import TBackOfficeUpdateInvShipmentAmount from '../models/backoffice/backoffice-update-inv-shipment-amount.type';
import TBackOfficeUpdateInvTransFileById from '../models/backoffice/backoffice-update-inv-trans-by-dile-id.type';
import TBackOfficeUpdateInvTransDate from '../models/backoffice/backoffice-update-inv-trans-date.type';
import TBackOfficeUpdateInvTransRaeson from '../models/backoffice/backoffice-update-inv-trans-reason.type';
import TBackOfficeUpdateStockAmount from '../models/backoffice/backoffice-update-stock-amount.type';
import TBackOfficeUpdateUser from '../models/backoffice/backoffice-update-user.type';
import TBackOfficeUser from '../models/backoffice/backoffice-user.type';
import TBackOfficeWorkOrderDetailsLikeab from '../models/backoffice/backoffice-work-order-details-likeab.type';
import TBackOfiiceWorkOrderE from '../models/backoffice/backoffice-work-order-e.type';
import TBackOfiiceWorkOrderTt from '../models/backoffice/backoffice-work-order-tt.type';
import TWorkOrderIE from '../models/work-order-ie.type';

namespace DBYSBackofficeServices {
  const backofficeApi = process.env.REACT_APP_DBYS_CRM_API!;

  /* export const getCompanyInfo = (payload: TAgentGetCompanyInfo) =>
    http.post<THttpResponse<TAgentCompanyInfo[]>>(`${backofficeApi}getcompanyInfo`, { ...payload }); */
  export const updateCompanyInfo = (payload: TBackOfficeUpdateCompanyInfo) =>
    http.post<THttpResponse<TAgentCompanyInfo[]>>(`${backofficeApi}updatecompanyinfo`, { ...payload });

  export const getcompanyuserswithcompanyvkn = (
    payload: TGetLogParameters<{
      identityNumber: string;
    }>
  ) => http.post<THttpResponse<TBackOfficeUser[]>>(`${backofficeApi}getcompanyuserswithcompanyvkn`, { ...payload });
  export const getworkorderinfoIE = (payload: TGetLogParameters<{ worOrderId: string }>) =>
    http.post<THttpResponse<TWorkOrderIE>>(`${backofficeApi}getworkorderieinfo`, { ...payload });
  export const updateworkorderinfoIE = ({
    command,
    fieldName,
    postData,
    value,
  }: {
    postData: TGetLogParameters<{ workOrderId: string }>;
    value: string;
    command: string;
    fieldName: string;
  }) => {
    return http.post<THttpResponse<{ afterProcessValue: string; data: { [key: string]: string } }>>(
      `${backofficeApi}${command}`,
      {
        ...postData,
        data: {
          ...postData.data,
          [fieldName]: value,
        },
      }
    );
  };
  export const getworkorderttinfo = (
    payload: TGetLogParameters<{
      workOrderId: string;
    }>
  ) => http.post<THttpResponse<TBackOfiiceWorkOrderTt>>(`${backofficeApi}getworkorderttinfo`, { ...payload });
  export const updateworkorderttinfo = (
    payload: TUpdateLogParameters<{
      workOrderId: string;
      dueDate: string;
    }>
  ) => http.post<THttpResponse<TBackOfiiceWorkOrderTt>>(`${backofficeApi}updateworkorderduedatebyid`, { ...payload });
  export const getworkorderseinfo = (
    payload: TUpdateLogParameters<{
      worOrderId: string;
    }>
  ) => http.post<THttpResponse<TBackOfiiceWorkOrderE>>(`${backofficeApi}getworkorderseinfo`, { ...payload });
  export const getcustomsubdnsrolesusers = (payload: TGetLogParameters<any>) =>
    http.post<THttpResponse<TBackOfficeCustomSubDnsRolesUsers>>(`${backofficeApi}getcustomsubdnsrolesusers`, {
      ...payload,
    });
  export const getinvshipmentdetails = (
    payload: TGetLogParameters<{
      invTransId: string;
    }>
  ) => http.post<THttpResponse<TBackOfficeInvShipmentDetails>>(`${backofficeApi}getinvshipmentdetails`, { ...payload });
  export const getustransactiondetails = (
    payload: TGetLogParameters<{
      workOrderId: string;
    }>
  ) =>
    http.post<THttpResponse<{ usTransactions: TBackofficeInventory[] }>>(`${backofficeApi}getustransactiondetails`, {
      ...payload,
    });

  export const updateinvtransdate = (payload: TBackOfficeUpdateInvTransDate) =>
    http.post<THttpResponse<TBackOfficeUpdateInvTransDate>>(`${backofficeApi}updateinvtransdate`, { ...payload });
  export const updateinvtransreason = (payload: TBackOfficeUpdateInvTransRaeson) =>
    http.post<THttpResponse<TBackOfficeUpdateInvTransRaeson>>(`${backofficeApi}updateinvtransreason`, { ...payload });
  export const updateinvtransbyfileid = (payload: TBackOfficeUpdateInvTransFileById) =>
    http.post<THttpResponse<TBackOfficeUpdateInvTransFileById>>(`${backofficeApi}updateinvtransbyfileid`, {
      ...payload,
    });
  export const updateshipmentamount = (payload: TBackOfficeUpdateInvShipmentAmount) =>
    http.post<THttpResponse<TBackOfficeUpdateInvShipmentAmount>>(`${backofficeApi}updateshipmentamount`, {
      ...payload,
    });
  export const getworkorderdetailslikeab = (
    payload: TGetLogParameters<{
      workOrderId: string;
    }>
  ) =>
    http.post<THttpResponse<TBackOfficeWorkOrderDetailsLikeab>>(`${backofficeApi}getworkorderdetailslikeab`, {
      ...payload,
    });
  export const updateaomproductname = (
    payload: TUpdateLogParameters<{
      workOrderId: string;
      productName: string;
    }>
  ) => http.post<THttpResponse<TBackOfiiceAomProductName>>(`${backofficeApi}updateaomproductname`, { ...payload });
  export const updatecompanyuserswithcompanyvknandid = (payload: TBackOfficeUpdateUser) =>
    http.post<THttpResponse<TBackOfficeUser>>(`${backofficeApi}updatecompanyuserswithcompanyvknandid`, { ...payload });
  export const updateproductamount = (
    payload: TUpdateLogParameters<{
      transactionId: number;
      amount: number;
    }>
  ) => http.post<THttpResponse<any>>(`${backofficeApi}updateproductamount`, { ...payload });
  export const updatewastageamount = (
    payload: TUpdateLogParameters<{
      transactionId: number;
      wastageAdJustAmount: number;
    }>
  ) => http.post<THttpResponse<any>>(`${backofficeApi}updatewastageamount`, { ...payload });
  export const getinvstockamountbypackagecode = (
    payload: TGetLogParameters<{
      packageCode: string;
    }>
  ) =>
    http.post<THttpResponse<TBackOfficeGetPackpageAmount>>(`${backofficeApi}getinvstockamountbypackagecode`, {
      ...payload,
    });
  export const updateinvstockamountbypackagecode = (payload: TBackOfficeUpdateStockAmount) =>
    http.post<THttpResponse<any>>(`${backofficeApi}updateinvstockamountbypackagecode`, { ...payload });
}

export default DBYSBackofficeServices;
