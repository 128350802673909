import { combineSlices } from '@reduxjs/toolkit';
import AgentOnlineCustomerStore from './slices/agetn-online-customer.slice';
import BackofficeInventoryStore from './slices/backoffice-inventory.slice';
import BackOfficeSearchStore from './slices/backoffice-search.slice';
import BackOfficeUpdateAomProductNameStore from './slices/backoffice-update-aom-product-name.slice';
import BackOfficeUpdateCompanyStore from './slices/backoffice-update-company.slice';
import BackOfficeUpdateInvShipmentStore from './slices/backoffice-update-inv-shipment.slice';
import BackOfficeUpdatePackPageAmontStore from './slices/backoffice-update-packpage-amount.slice';
import BackOfficeUpdateUserStore from './slices/backoffice-update-users.slice';
import BackOfficeUpdateWorkOrderEStore from './slices/backoffice-update-work-order-e.slice';
import BackOfficeUpdateWorkOrderTtStore from './slices/backoffice-updatework-order-tt.slice';
import IssueListStore from './slices/issue-list.slice';
import BackOfficeUpdateWorkOrderIEStore from './slices/backoffice-update-work-order-ie.slice';
import IssueFileStore from './slices/issue-file.slice';
import OfflineIssueStore from './slices/offline-issue.slice';
import GetIssueByIdStore from './slices/get-issue-by-id.slice';

const dbysReducers = combineSlices({
  agentOnlineCustomer: AgentOnlineCustomerStore.reducer,
  backofficeSearch: BackOfficeSearchStore.reducer,
  issueList: IssueListStore.reducer,
  backofficeUpdateCompany: BackOfficeUpdateCompanyStore.reducer,
  backofficeUpdateUser: BackOfficeUpdateUserStore.reducer,
  offlineIssue: OfflineIssueStore.reducer,
  backOfficeUpdateWorkOrders: BackOfficeUpdateWorkOrderIEStore.backOfficeUpdateWorkOrderReducer,
  status: BackOfficeUpdateWorkOrderIEStore.statusListReducer,
  backOfficeUpdateWorkOrderTt: BackOfficeUpdateWorkOrderTtStore.reducer,
  backOfficeUpdateWorkOrderE: BackOfficeUpdateWorkOrderEStore.reducer,
  backOfficeUpdateInvShipment: BackOfficeUpdateInvShipmentStore.reducer,
  backOfficeUpdateAomProductName: BackOfficeUpdateAomProductNameStore.reducer,
  backofficeInventory: BackofficeInventoryStore.reducer,
  backOfficeUpdatePackPageAmont: BackOfficeUpdatePackPageAmontStore.reducer,
  issueFile: IssueFileStore.reducer,
  getIssueById: GetIssueByIdStore.reducer
});

export default dbysReducers;
