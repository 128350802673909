'use client';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import KeycloakStore from './slice/keycloak.slice';
import ToastMessageStore from './slice/toast-message.slice';
import PreloaderStore from './slice/preloader.slice';
import AgentCallStore from './slice/agent-call.slice';
import dbysReducers from '../dbys/store';

namespace AppStore {
  export const store = configureStore({
    reducer: {
      dbys: dbysReducers,
      keycloak: KeycloakStore.reducer,
      toastMessage: ToastMessageStore.reducer,
      preloader: PreloaderStore.reducer,
      agentCall: AgentCallStore.reducer,
    },
  });
  export type RootState = ReturnType<typeof store.getState>;
  export type AppDispatch = typeof store.dispatch;
  export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
  export const useAppSelector = useSelector.withTypes<RootState>();
}

export default AppStore;
