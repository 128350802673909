import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import BackOfficeUpdateWorkOrdersThunks from '../async-thunks/backoffice-update-work-orders.thunk';
import TBackOfficeStatusParameters from '../../models/backoffice/backoffice-status-parameters.type';

namespace BackOfficeUpdateWorkOrderIEStore {
  type TBackOfficeUpdateWorkOrderStore = {
    companyId: string;
    companyName: string;
    repeatCount: string;
    columnCount: string;
    workOrderValue: string;
    status: string;
    itemPerSheet: string;
  };

  type TStatusListState = TBackOfficeStatusParameters[];

  const initialState: TBackOfficeUpdateWorkOrderStore = {
    companyId: '',
    companyName: '',
    repeatCount: '',
    columnCount: '',
    workOrderValue: '',
    status: '',
    itemPerSheet: '',
  };

  const initialStatusListState: TStatusListState = [];

  const backOfficeUpdateWorkOrderSlice = createSlice({
    name: 'backOfficeUpdateWorkOrder',
    initialState,
    reducers: {
      update: (state: TBackOfficeUpdateWorkOrderStore, action: PayloadAction<TBackOfficeUpdateWorkOrderStore>) => {
        return { ...action.payload };
      },
      reset: () => initialState,
    },
    extraReducers: (builder) => {
      builder.addCase(BackOfficeUpdateWorkOrdersThunks.getWorkOrder.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.companyId = data?.companyId;
        state.companyName = data?.companyName;
        state.repeatCount = data?.repeatCount;
        state.columnCount = data?.columnCount;
        state.workOrderValue = data?.workOrderValue;
        state.status = data?.status;
        state.itemPerSheet = data?.itemPerSheet;
      });

      builder.addCase(BackOfficeUpdateWorkOrdersThunks.updateWorkOrder.fulfilled, (state, action) => {
        state[action.payload.data?.data['fieldName'] as keyof TBackOfficeUpdateWorkOrderStore] =
          action.payload.data?.afterProcessValue;
      });
    },
  });

  const statusListSlice = createSlice({
    name: 'statusList',
    initialState: initialStatusListState,
    reducers: {
      resetStatusList: () => initialStatusListState,
    },
    extraReducers: (builder) => {
      builder.addCase(BackOfficeUpdateWorkOrdersThunks.getWorkOrderStatusList.fulfilled, (state, action) => {
        return action.payload.data;
      });
    },
  });

  export const backOfficeUpdateWorkOrderActions = backOfficeUpdateWorkOrderSlice.actions;
  export const statusListActions = statusListSlice.actions;

  export const backOfficeUpdateWorkOrderReducer = backOfficeUpdateWorkOrderSlice.reducer;
  export const statusListReducer = statusListSlice.reducer;

  export const select = {
    workOrderIE: (state: AppStore.RootState) => state.dbys.backOfficeUpdateWorkOrders,
    statusList: (state: AppStore.RootState) => state.dbys.status,
  };
}

export default BackOfficeUpdateWorkOrderIEStore;
