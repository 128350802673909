import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import BackOfficeInventoryManagementThunks from '../async-thunks/backoffice-inventory-management.thunk';
import TBackofficeInventory from '../../models/backoffice/backoffice-inventory.type';

namespace BackofficeInventoryStore {
  type TBackofficeInventoryStore = {
    inventoryList: TBackofficeInventory[];
    selectedInventory: TBackofficeInventory | undefined;
  };

  const initialState: TBackofficeInventoryStore = {
    inventoryList: [],
    selectedInventory: undefined,
  };

  const backofficeInventorySlice = createSlice({
    name: 'backofficeInventory',
    initialState,
    reducers: {
      setSelectedInventory: (state, action: { payload: TBackofficeInventory | undefined }) => {
        state.selectedInventory = action.payload;
      },
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder.addCase(BackOfficeInventoryManagementThunks.getustransactiondetails.fulfilled, (state, action) => {
        if (action.payload.status) {
          // sadece mamul, yarı mamul ve fire islemleri gösterilecektir.
          const filteredList = action.payload.data.usTransactions.filter(
            (item) =>
              item.type === 'Mamul' ||
              item.type === 'Yarı Mamul' ||
              item.type === 'Fire' ||
              item.type === 'Mamül' ||
              item.type === 'Yarı Mamül'
          );
          state.inventoryList = [...filteredList];
        }
      });
    },
  });

  export const actions = backofficeInventorySlice.actions;
  export const reducer = backofficeInventorySlice.reducer;
  export const select = {
    getInventoryList: (state: AppStore.RootState) => state.dbys.backofficeInventory.inventoryList,
    getSelectedInventory: (state: AppStore.RootState) => state.dbys.backofficeInventory.selectedInventory,
  };
}

export default BackofficeInventoryStore;
