'use client';
import { useEffect } from 'react';
import { Toast } from 'flowbite-react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import ToastMessageStore from '../../../store/slice/toast-message.slice';
import useToastMessageStore from '../../../store/hooks/use-toast-store.hook';

/**
 * Component that displays toast messages.
 * @returns JSX.Element The toast container component.
 */
const ToastContainer = (): JSX.Element => {
  // Get toast messages and delete toast message action from the toast message store
  const {
    toastMessages, // Array of toast messages
    deleteToastMessage, // Function to delete a toast message
  }: {
    // Object containing toast messages and delete toast message function
    toastMessages: ToastMessageStore.TToastrMessage[]; // Array of toast messages
    deleteToastMessage: (id: string) => void; // Function to delete a toast message by id
  } = useToastMessageStore(); // Get toast messages and delete toast message action from the toast message store

  // Set up a timer for each toast message that deletes it after 3 seconds
  useEffect(() => {
    const timers: NodeJS.Timeout[] = []; // Array to store the timers

    // If there are toast messages
    if (toastMessages) {
      // For each toast message, create a timer that deletes it after 3 seconds
      toastMessages.forEach((toast) => {
        const timer = setTimeout(() => {
          if (toast && toast.id) {
            // If the toast message has an id
            deleteToastMessage(toast.id); // Delete the toast message by id
          }
        }, 6000); // 3000ms = 3 seconds
        timers.push(timer); // Add the timer to the array
      });
    }

    // When the component unmounts, clear all the timers
    return () => {
      timers.forEach(clearTimeout); // Clear each timer in the array
    };
  }, [toastMessages, deleteToastMessage]); // Rerun the effect when toast messages or deleteToastMessage changes

  if (!toastMessages || toastMessages.length === 0) {
    return <></>;
  }
  // Render a toast component for each toast message
  return (
    <div
      aria-label="Toast Container"
      className="fixed top-0 left-0 right-0 flex flex-col gap-4 z-20 items-center justify-center p-4"
    >
      {toastMessages?.map((toast, index) => (
        <Toast key={index}>
          {/* Display an icon based on the toast type */}
          <div
            className={`shadow-lg inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${getToastClass(
              toast?.type // Toast type
            )}`}
          >
            {toast?.type && getToastIcon(toast.type)} {/* Display the icon based on the toast type */}
          </div>
          {/* Display the toast text */}
          <div className="ml-3 text-sm font-normal">{toast?.text}</div> {/* Display the toast text */}
          <Toast.Toggle /> {/* Toggle button to close the toast */}
        </Toast>
      ))}
    </div>
  );
};

/**
 * getToastClass function is used to get the CSS class name for a toast based on its type.
 * @param {string | undefined} type - The type of the toast ('success', 'error', 'warning', 'info').
 * @throws {TypeError} If the type parameter is null or undefined.
 * @return {string} The CSS class name for the toast.
 */
const getToastClass = (type: string | undefined): string => {
  // Throw a TypeError if the type parameter is null or undefined.
  if (type === null || type === undefined) {
    throw new TypeError('Type parameter cannot be null or undefined');
  }

  // Use a switch statement to determine the CSS class name based on the type of the toast.
  switch (type) {
    // If the type is 'success', return the CSS class name for a success toast.
    case 'success':
      return 'bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200';
    // If the type is 'error', return the CSS class name for an error toast.
    case 'error':
      return 'bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200';
    // If the type is 'warning', return the CSS class name for a warning toast.
    case 'warning':
      return 'bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200';
    // If the type is 'info', return the CSS class name for an info toast.
    case 'info':
      return 'bg-blue-100 text-blue-500';
    // If the type is not recognized, return an empty string.
    default:
      return '';
  }
};

/**
 * getToastIcon function is used to get the JSX element for an icon based on the type of the toast.
 * The function takes in a string parameter 'type' which represents the type of the toast.
 * The function returns a JSX element that represents the icon for the toast.
 * The JSX element could be one of the following:
 * - HiCheck component for a success toast
 * - HiX component for an error toast
 * - HiExclamation component for a warning toast
 * - HiOutlineInformationCircle component for an info toast
 * If the type parameter is null or undefined, the function throws a TypeError.
 * If the type parameter is not recognized, the function returns an empty string.
 * @param {string | null | undefined} type - The type of the toast ('success', 'error', 'warning', 'info').
 * @throws {TypeError} If the type parameter is null or undefined.
 * @return {JSX.Element | ''} The JSX element for the icon or an empty string if the type is not recognized.
 */
const getToastIcon = (type: 'success' | 'error' | 'warning' | 'info' | null | undefined): JSX.Element | '' => {
  // Check if the type parameter is null or undefined.
  // If it is, throw a TypeError.
  if (type === null || type === undefined) {
    throw new TypeError('Type parameter cannot be null or undefined');
  }

  // Use a switch statement to determine the JSX element based on the type of the toast.
  switch (type) {
    // If the type is 'success', return the JSX element for a success toast.
    case 'success':
      // The JSX element is a HiCheck component with a class name of 'h-5 w-5'.
      return <CheckCircleIcon className="h-5 w-5" />;
    // If the type is 'error', return the JSX element for an error toast.
    case 'error':
      // The JSX element is a HiX component with a class name of 'h-5 w-5'.
      return <ExclamationCircleIcon className="h-5 w-5" />;
    // If the type is 'warning', return the JSX element for a warning toast.
    case 'warning':
      // The JSX element is a HiExclamation component.
      return <ExclamationTriangleIcon className="h-5 w-5" />;
    // If the type is 'info', return the JSX element for an info toast.
    case 'info':
      // The JSX element is a HiOutlineInformationCircle component.
      return <InformationCircleIcon className="h-5 w-5" />;
    // If the type is not recognized, return an empty string.
    default:
      return '';
  }
};

export default ToastContainer;
