import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import KeycloakProvider from './keycloak/keycloak.provider';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Provider } from 'react-redux';
import AppStore from './store';
import Preloader from './dbys/components/preloader/preloader.component';
import ToastContainer from './dbys/components/toast/toast.component';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <KeycloakProvider>
    <Provider store={AppStore.store}>
      <RouterProvider router={router} />
      <ToastContainer />
      <Preloader />
    </Provider>
  </KeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
