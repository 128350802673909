import http from '../../http';
import TCreateIssue from '../models/create-issue.type';
import TGetAttachmentList from '../models/get-attachment-list.type';
import TGetCreateIssue from '../models/get-create-issue.type';
import TGetIssueByID from '../models/get-issue-by-id.type';
import TIssueList from '../models/issue-list.type';
import TLastFiveIssue from '../models/last-five-issue.type';
import TUpdateIssue from '../models/update-issue.type';

namespace DBYSJiraServices {
  const crmApi = process.env.REACT_APP_DBYS_CRM_API_JIRA!;

  export const getLastFiveIssueByVKN = ({ taxNumber, logData }: { taxNumber: string; logData: TJIRAPayload }) =>
    http.post<THttpResponse<TLastFiveIssue>>(
      `${crmApi}GetLastFiveIssueByVKN?taxNumber=${taxNumber}`,
      { ...logData },
      {
        baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
        headers: {
          isHideErrorMessage: 'true',
        },
      }
    );

  export const getIssueList = ({
    endCount,
    startCount,
    logData,
  }: {
    startCount: number;
    endCount: number;
    logData: TJIRAPayload;
  }) =>
    http.post<THttpResponse<TIssueList>>(
      `${crmApi}GetIssueList?startCount=${startCount}&endCount=${endCount}`,
      { ...logData },
      {
        baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
        headers: {
          isHideErrorMessage: 'true',
        },
      }
    );

  export const getCreateIssue = (payload: TJIRAPayload<TGetCreateIssue>) =>
    http.post<THttpResponse<TCreateIssue>>(
      `${crmApi}CreateIssue?isMissedCall=false`,
      { ...payload },
      {
        baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
      }
    );

  export const getIssueById = ({ issueID, logData }: { issueID: string; logData: TJIRAPayload; }) =>
    http.post<THttpResponse<TGetIssueByID>>(`${crmApi}getIssueById?issueId=${issueID}`, { ...logData }, {
      baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
    });

  export const getAttachmentList = (issueID: string) =>
    http.get<THttpResponse<TGetAttachmentList>>(`${crmApi}GetAttachmentList?issueId=${issueID}`, {
      baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
      headers: {
        isHideErrorMessage: 'true',
      },
    });
  export const getAttachmen = (attachmentId: string) =>
    http.get<Blob>(`${crmApi}DownloadAttachment?attachementId=${attachmentId}`, {
      baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
      responseType: 'blob',
    });

  export const updateIssue = (payload: TUpdateIssue, issueID: string) =>
    http.put<THttpResponse<any>>(
      `${crmApi}UpdateIssue?issueId=${issueID}`,
      { ...payload },
      {
        baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
      }
    );

  export const getUpdateStatus = ({ issueId }: { issueId: string }) =>
    http.post<THttpResponse<any>>(
      `${crmApi}UpdateStatus?issueId=${issueId}`,
      {
        transition: {
          id: '511',
          name: 'Complete Directly',
        },
      },
      {
        baseURL: process.env.REACT_APP_DBYS_BASE_API_JIRA!,
      }
    );
}

export default DBYSJiraServices;
