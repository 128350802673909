import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';

namespace BackOfficeInventoryManagementThunks {
  export const getustransactiondetails = createAsyncThunk(
    'backoffice/getustransactiondetails',
    async (
      payload: TGetLogParameters<{
        workOrderId: string;
      }>
    ) => {
      const response = await DBYSBackofficeServices.getustransactiondetails({
        ...payload,
      });
      return response.data;
    }
  );
  export const updateproductamount = createAsyncThunk(
    'backoffice/updateproductamount',
    async (
      payload: TUpdateLogParameters<{
        transactionId: number;
        amount: number;
      }>
    ) => {
      const response = await DBYSBackofficeServices.updateproductamount({
        ...payload,
      });
      return response.data;
    }
  );
  export const updatewastageamount = createAsyncThunk(
    'backoffice/updatewastageamount',
    async (
      payload: TUpdateLogParameters<{
        transactionId: number;
        wastageAdJustAmount: number;
      }>
    ) => {
      const response = await DBYSBackofficeServices.updatewastageamount({
        ...payload,
      });
      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }
      return response.data;
    }
  );
}

export default BackOfficeInventoryManagementThunks;
