import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '..';

namespace AgentCallStore {
  type TAgentCallStore = {
    customerPhoneNumber: string;
    projectName: string;
    isMissedCall: boolean;
    isDisableSearch: boolean;
  };

  const initialState: TAgentCallStore = {
    customerPhoneNumber: '',
    projectName: '',
    isMissedCall: false,
    isDisableSearch: false,
  };

  const agentCallSlice = createSlice({
    name: 'agentCall',
    initialState,
    reducers: {
      setCustomerPhoneNumber: (state: TAgentCallStore, action: PayloadAction<string>) => {
        state.customerPhoneNumber = action.payload;
      },
      setProjectName: (state: TAgentCallStore, action: PayloadAction<string>) => {
        state.projectName = action.payload;
      },
      setIsMissedCall: (state: TAgentCallStore, action: PayloadAction<boolean>) => {
        state.isMissedCall = action.payload;
      },
      setIsDisableSearch: (state: TAgentCallStore, action: PayloadAction<boolean>) => {
        state.isDisableSearch = action.payload;
      },
      reset: () => initialState,
    },
  });

  export const actions = agentCallSlice.actions;
  export const reducer = agentCallSlice.reducer;
  export const select = {
    customerPhoneNumber: (state: AppStore.RootState) => state.agentCall.customerPhoneNumber,
    projectName: (state: AppStore.RootState) => state.agentCall.projectName,
    isMissedCall: (state: AppStore.RootState) => state.agentCall.isMissedCall,
    isDisableSearch: (state: AppStore.RootState) => state.agentCall.isDisableSearch,
  };
}

export default AgentCallStore;
