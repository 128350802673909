import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import Utils from '../../../utils';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';

namespace BackOfficeGetWorkOrderTtThunks {
  export const getWorkOrderTt = createAsyncThunk(
    'backoffice/getWorkOrderTt',
    async ({ username, workOrderId }: { username: string; workOrderId: string }) => {
      const response = await DBYSBackofficeServices.getworkorderttinfo({
        corelationId: Utils.getUUID(),
        executedByOutSourceUser: username || '',
        data: { workOrderId },
      });
      return response.data;
    }
  );

  export const updateWorkOrderTt = createAsyncThunk(
    'backoffice/updateWorkOrderTt',
    async (
      payload: TUpdateLogParameters<{
        workOrderId: string;
        dueDate: string;
      }>
    ) => {
      const response = await DBYSBackofficeServices.updateworkorderttinfo({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode,
        data: {
          workOrderId: payload.data.workOrderId,
          dueDate: payload.data.dueDate,
        },
      });

      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }

      return response.data;
    }
  );
}

export default BackOfficeGetWorkOrderTtThunks;
