import axios, { AxiosError, AxiosResponse } from 'axios';
import AppStore from '../store';
import PreloaderStore from '../store/slice/preloader.slice';
import ToastMessageStore from '../store/slice/toast-message.slice';
import { keycloakInstance } from '../keycloak/keycloak.provider';

const isHideErrorMessageList: { [key: string]: boolean } = {};

const http = axios.create({
  baseURL: process.env.REACT_APP_DBYS_BASE_API!,
});

http.interceptors.request.use(
  async (config) => {
    const isTokenExpired = keycloakInstance.isTokenExpired();

    if (isTokenExpired) {
      await keycloakInstance.updateToken();
    }
    AppStore.store.dispatch(PreloaderStore.actions.increaseLoadingCount());
    if (config.headers) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('idToken')}`;
    }
    const isHideErrorMessage = config.headers.get('isHideErrorMessage');
    if (isHideErrorMessage && config.url) {
      isHideErrorMessageList[config.url] = true;
    }
    return config;
  },
  (error) => {
    AppStore.store.dispatch(PreloaderStore.actions.decreaseLoadingCount());
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response: AxiosResponse<THttpResponse<any>>) => {
    if (response.data.status === false) {
      if (isHideErrorMessageList[response.config?.url!]) {
        delete isHideErrorMessageList[response.config?.url!];
      } else {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({ text: response.data.message, type: 'error' })
        );
      }
    }

    AppStore.store.dispatch(PreloaderStore.actions.decreaseLoadingCount());
    return response;
  },
  async (error: AxiosError) => {
    if (error.status === 401) {
      keycloakInstance.login();
    }
    console.error(error);
    AppStore.store.dispatch(ToastMessageStore.actions.addToastMessage({ text: error.message, type: 'error' }));
    AppStore.store.dispatch(PreloaderStore.actions.decreaseLoadingCount());
    return Promise.reject(error);
  }
);

export default http;
