import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import BackOfficeUserWithVknThunks from '../async-thunks/backoffice-update-users.thunks';
import TBackOfficeUser from '../../models/backoffice/backoffice-user.type';

namespace BackOfficeUpdateUserStore {
  type TBackOfficeUpdateUserSlice = {
    companyVKN: string;
    selectedUser: TBackOfficeUser[];
  };

  export const initialState: TBackOfficeUpdateUserSlice = {
    companyVKN: '',
    selectedUser: [],
  };

  const backOfficeUpdateUserSlice = createSlice({
    name: 'backOfficeUpdateUser',
    initialState,
    reducers: {
      setCompanyVKN: (state, action: PayloadAction<string>) => {
        state.companyVKN = action.payload;
      },
      reset: (state) => {
        state.selectedUser = [];
      },
    },
    extraReducers: (builder) => {
      builder.addCase(BackOfficeUserWithVknThunks.getUsersWithVkn.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.selectedUser = action.payload.data;
        }
      });
    },
  });

  export const actions = backOfficeUpdateUserSlice.actions;
  export const reducer = backOfficeUpdateUserSlice.reducer;
  export const select = {
    getSelectedUser: (state: AppStore.RootState) => state.dbys.backofficeUpdateUser.selectedUser,
    getCompanyVKN: (state: AppStore.RootState) => state.dbys.backofficeUpdateUser.companyVKN,
  };
}

export default BackOfficeUpdateUserStore;
