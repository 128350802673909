import { createSlice, PayloadAction } from '@reduxjs/toolkit';

namespace KeycloakStore {
  type TKeycloakState = {
    username: string;
    userFullname: string;
    email: string;
  };

  const initialState: TKeycloakState = {
    username: '',
    userFullname: '',
    email: '',
  };

  const keycloakSlice = createSlice({
    name: 'keycloak',
    initialState,
    reducers: {
      setUsername: (state: TKeycloakState, action: PayloadAction<string>) => {
        state.username = action.payload;
      },
      setUserFullname: (state: TKeycloakState, action: PayloadAction<string>) => {
        state.userFullname = action.payload;
      },
      setEmail: (state: TKeycloakState, action: PayloadAction<string>) => {
        state.email = action.payload;
      }
    },
  });

  export const actions = keycloakSlice.actions;
  export const reducer = keycloakSlice.reducer;
  export const select = {
    username: (state: { keycloak: TKeycloakState }) => state.keycloak.username,
    userFullname: (state: { keycloak: TKeycloakState }) => state.keycloak.userFullname,
    email: (state: { keycloak: TKeycloakState }) => state.keycloak.email
  };
}

export default KeycloakStore;
