import http from '../../http';
import TAgentCompanyInfo from '../models/agent-company-info.type';
import TAgentGetCompanyInfo from '../models/agent-get-company-info.type';
import TBackOfficeStatusParameters from '../models/backoffice/backoffice-status-parameters.type';
import TContactHistory from '../models/contact-history.type';
import TGetContactHistory from '../models/get-contact-history.type';
import TGetPriority from '../models/get-priority.type';
import TGetRequestType from '../models/get-request-type.type';
import TPrioritype from '../models/priority.type';
import TGetRequestReason from '../models/request-reason-list.type';
import TRequestType from '../models/request.type';
import TGetStolkholderType from '../models/stolkholder-type.tye';
import TGetSubRequestReason from '../models/sub-request-reason-list.type';

namespace DBYSCRMServices {
  const api = process.env.REACT_APP_DBYS_API!;
  const crmApi = process.env.REACT_APP_DBYS_CRM_API!;
  const parametersapi = process.env.REACT_APP_DBYS_CRM_PARAMETER_API!;

  export const getCompanyInfo = (payload: TAgentGetCompanyInfo) =>
    http.post<THttpResponse<TAgentCompanyInfo[]>>(`${crmApi}getcompanyInfo`, { ...payload });
  export const getallcompanyinfo = (
    payload: TGetLogParameters<{
      companyNameOrVknSearch: string;
    }>
  ) => http.post<THttpResponse<TAgentCompanyInfo[]>>(`${crmApi}getallcompanyinfo`, { ...payload });
  export const getContactHistory = (payload: TGetContactHistory) =>
    http.post<THttpResponse<TContactHistory[]>>(
      `${api}ContactHistory/getcontacthistorylist`,
      { ...payload },
      {
        headers: {
          isHideErrorMessage: 'true',
        },
      }
    );
  export const getpriority = (payload: TGetPriority) =>
    http.post<THttpResponse<TPrioritype[]>>(`${parametersapi}getpriority`, { ...payload });
  export const getReasonRequest = (payload: TGetRequestReason) =>
    http.post<THttpResponse<TRequestType[]>>(`${parametersapi}getreasonforrequestparameterlist`, { ...payload });
  export const getTicketTypeList = (payload: TGetRequestType) =>
    http.post<THttpResponse<TRequestType[]>>(`${parametersapi}getrequesttypeparameterlist`, { ...payload });
  export const getworkorderstatusparameterlist = () =>
    http.post<THttpResponse<TBackOfficeStatusParameters[]>>(`${parametersapi}getworkorderstatusparameterlist`);
  export const getStolkholderType = (payload: TGetStolkholderType) =>
    http.post<THttpResponse<TRequestType[]>>(`${parametersapi}getstolkholderstype`, { ...payload });
  export const getSubReasonRequest = (payload: TGetSubRequestReason) =>
    http.post<THttpResponse<TRequestType[]>>(`${parametersapi}getdemandsubreasonparameterlist`, { ...payload });
}

export default DBYSCRMServices;
