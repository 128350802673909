import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSJiraServices from '../../services/jira-services';

namespace GetIssueByIdThunks {
  export const getIssueById = createAsyncThunk('getIssueById', async ({ issueID, logData }: { issueID: string, logData: TJIRAPayload }) => {
    const response = await DBYSJiraServices.getIssueById({issueID, logData});
    return response.data;
  });
}

export default GetIssueByIdThunks;
