'use client';
import AppStore from '..';
import ToastMessageStore from '../slice/toast-message.slice';

const useToastMessageStore = () => {
  const dispatch = AppStore.useAppDispatch();
  const toastMessages = AppStore.useAppSelector(ToastMessageStore.select.messageList);

  const addToastMessageHandler = (text: string, type: ToastMessageStore.TToastMessageTypes) => {
    dispatch(ToastMessageStore.actions.addToastMessage({ text, type }));
  };

  const deleteToastMessageHandler = (id: string) => {
    dispatch(ToastMessageStore.actions.deleteToastMessage(id));
  };

  return {
    toastMessages,
    addToastMessage: addToastMessageHandler,
    deleteToastMessage: deleteToastMessageHandler,
  };
};

export default useToastMessageStore;
