import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import AppStore from '..';

namespace PreloaderStore {
  export type PreloaderState = {
    loadingCount: number;
    loadingMessageList: string[];
  };

  const initialState: PreloaderState = {
    loadingCount: 0,
    loadingMessageList: [],
  };

  const preloaderSlice = createSlice({
    name: 'preloader',
    initialState,
    reducers: {
      increaseLoadingCount(state) {
        state.loadingCount += 1;
      },

      decreaseLoadingCount(state) {
        state.loadingCount -= 1;
        if (state.loadingCount < 0) {
          state.loadingCount = 0;
        }
      },
      addLoadingMessage(state, action: PayloadAction<string>) {
        state.loadingMessageList.push(action.payload);
      },
      removeLoadingMessage(state, action: PayloadAction<string>) {
        state.loadingMessageList = state.loadingMessageList.filter((message) => message !== action.payload);
      },
    },
  });

  export const actions = preloaderSlice.actions;
  export const reducer = preloaderSlice.reducer;
  export const usePreloaderStore = () => useSelector((state: AppStore.RootState) => state.preloader);
}

export default PreloaderStore;
