import { Spinner } from 'flowbite-react';
import React from 'react';
import PreloaderStore from '../../../store/slice/preloader.slice';

/**
 * Preloader component.
 *
 * This component is responsible for displaying a preloader screen when there are
 * ongoing loading operations. It is a functional component that uses the `PreloaderStore`
 * to get the loading state and render the preloader screen accordingly.
 *
 * @return {JSX.Element | null} The preloader component or `null` if there are no ongoing
 * loading operations.
 */
const Preloader: React.FC = (): JSX.Element | null => {
  // Get the loading state from the `PreloaderStore`.
  // The `PreloaderStore.usePreloaderStore()` hook returns an object with two properties:
  // `loadingMessageList` and `loadingCount`.
  // `loadingMessageList` is an array of strings representing the messages to be displayed
  // while loading is ongoing.
  // `loadingCount` is a number representing the count of ongoing loading operations.
  const { loadingMessageList, loadingCount }: PreloaderStore.PreloaderState = PreloaderStore.usePreloaderStore();

  // If there are no ongoing loading operations, return `null`.
  // `loadingCount` is compared to `1` because `loadingCount` represents the count of
  // ongoing loading operations, and we want to display the preloader screen only when
  // there is at least one ongoing loading operation.
  if (loadingCount === undefined || loadingCount < 1) {
    return null;
  }

  // Render the preloader screen.
  // The preloader screen is a `div` element with some CSS classes that define its
  // appearance and behavior.
  // The `div` element contains two sections: a spinner and a list of loading messages.
  return (
    <div className="fixed top-0 left-0 flex flex-col gap-6 justify-center w-full h-full bg-white bg-opacity-60">
      {/* Spinner */}
      {/* The spinner is a `div` element with some CSS classes that define its
          appearance and behavior. It is a visual representation of the loading state. */}
      <div className="flex flex-col gap-2 items-center">
        <Spinner />
      </div>

      {/* Loading message list */}
      {/* If `loadingMessageList` is not `undefined` and has at least one message,
          render the list of loading messages. */}
      {loadingMessageList ? (
        <div className="flex flex-col gap-2 items-center">
          {/* Render each loading message as a `div` element with a unique `key` prop.
              The `key` prop is used by React to optimize rendering performance. */}
          {loadingMessageList.map((message: string, index: number) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Preloader;
