import { createAsyncThunk } from '@reduxjs/toolkit';
import DBYSBackofficeServices from '../../services/backoffice.services';
import TBackOfficeUpdateInvTransDate from '../../models/backoffice/backoffice-update-inv-trans-date.type';
import AppStore from '../../../store';
import ToastMessageStore from '../../../store/slice/toast-message.slice';
import TBackOfficeUpdateInvTransRaeson from '../../models/backoffice/backoffice-update-inv-trans-reason.type';
import TBackOfficeUpdateInvTransFileById from '../../models/backoffice/backoffice-update-inv-trans-by-dile-id.type';
import TBackOfficeUpdateInvShipmentAmount from '../../models/backoffice/backoffice-update-inv-shipment-amount.type';

namespace BackOfficeUpdateInvShipmentThunks {
  export const getInvShipment = createAsyncThunk(
    'backoffice/getInvShipment',
    async (
      payload: TGetLogParameters<{
        invTransId: string;
      }>
    ) => {
      const response = await DBYSBackofficeServices.getinvshipmentdetails(payload);
      return response.data;
    }
  );
  export const updateinvtransdate = createAsyncThunk(
    'backoffice/updateinvtransdate',
    async (payload: TBackOfficeUpdateInvTransDate) => {
      const response = await DBYSBackofficeServices.updateinvtransdate({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode || '',
        data: {
          invTransId: payload.data.invTransId,
          date: payload.data.date,
        },
      });

      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }

      return response.data;
    }
  );
  export const updateinvtransreason = createAsyncThunk(
    'backoffice/updateinvtransreason',
    async (payload: TBackOfficeUpdateInvTransRaeson) => {
      const response = await DBYSBackofficeServices.updateinvtransreason({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode || '',
        data: {
          invTransId: payload.data.invTransId,
          reason: payload.data.reason,
        },
      });

      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }

      return response.data;
    }
  );
  export const updateinvtransbyfileid = createAsyncThunk(
    'backoffice/updateinvtransbyfileid',
    async ({ payload, username }: { username: string; payload: TBackOfficeUpdateInvTransFileById }) => {
      const response = await DBYSBackofficeServices.updateinvtransbyfileid({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode || '',
        data: {
          fileGuid: payload.data.fileGuid,
          invTransId: payload.data.invTransId,
          supportRequestCode: payload.data.supportRequestCode,
          description: payload.data.description,
          username: username,
        },
      });

      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }

      return response.data;
    }
  );
  export const updateshipmentamount = createAsyncThunk(
    'backoffice/updateshipmentamount',
    async (payload: TBackOfficeUpdateInvShipmentAmount) => {
      const response = await DBYSBackofficeServices.updateshipmentamount({
        corelationId: payload.corelationId,
        executedByOutSourceUser: payload.executedByOutSourceUser || '',
        supportRequestCode: payload.supportRequestCode || '',
        data: {
          transactionId: payload.data.transactionId,
          shipmentAdJustAmount: payload.data.shipmentAdJustAmount,
        },
      });

      if (response.data.status) {
        AppStore.store.dispatch(
          ToastMessageStore.actions.addToastMessage({
            type: 'success',
            text: response.data.message,
          })
        );
      }

      return response.data;
    }
  );
}

export default BackOfficeUpdateInvShipmentThunks;
