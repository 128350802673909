import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import IssueFileThunks from '../async-thunks/issue-file.thunks';
import TGetAttachmentList from '../../models/get-attachment-list.type';

namespace IssueFileStore {
    type TIssueFileSlice = {
        getAttachmentList: TGetAttachmentList | undefined;
    };

    export const initialState: TIssueFileSlice = {
        getAttachmentList: undefined,
    };

    const IssueFileSlice = createSlice({
        name: 'IssueFile',
        initialState,
        reducers: {
            reset: () => initialState,
        },

        extraReducers: (builder) => {
            builder.addCase(IssueFileThunks.getAttachmentList.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.getAttachmentList = action.payload.data;
                }
            });
        }
    });

    export const actions = IssueFileSlice.actions;
    export const reducer = IssueFileSlice.reducer;
    export const select = {
        getAttachmentList: (state: AppStore.RootState) => state.dbys.issueFile.getAttachmentList,
    };
}

export default IssueFileStore;
