import { createSlice } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TBackOfficeGetPackpageAmount from '../../models/backoffice/backoffice-get-packpage-amount.type';
import BackOfficeGetPackpageAmountThunks from '../async-thunks/backoffice-update-packpage-amount.thunks';

namespace BackOfficeUpdatePackPageAmontStore {
    type TBackOfficeUpdatePackPageAmontSlice = {
        packPageAmount: TBackOfficeGetPackpageAmount | undefined;
    };

    export const initialState: TBackOfficeUpdatePackPageAmontSlice = {
        packPageAmount: undefined,
    };

    const backOfficeUpdatePackPageAmontSlice = createSlice({
        name: 'backOfficeUpdatePackPageAmont',
        initialState,
        reducers: {
            reset: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(BackOfficeGetPackpageAmountThunks.getPackpageAmount.fulfilled, (state, action) => {
                if (action.payload.status) {
                    state.packPageAmount = action.payload.data;
                }
            });
        },
    });

    export const actions = backOfficeUpdatePackPageAmontSlice.actions;
    export const reducer = backOfficeUpdatePackPageAmontSlice.reducer;
    export const select = {
        packpageAmount: (state: AppStore.RootState) => state.dbys.backOfficeUpdatePackPageAmont.packPageAmount,
    };
}

export default BackOfficeUpdatePackPageAmontStore;
