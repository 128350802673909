import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import BackOfficeSearchTypeList from '../../../contants/backoffice-search-type-list.enum';

namespace BackOfficeSearchStore {
  export type TBackOfficeSearchTypes = keyof typeof BackOfficeSearchTypeList;
  type TBackOfficeSearchStore = {
    searchKeyword: string;
    currentSearchType: TBackOfficeSearchTypes | undefined;
    canSearch: boolean;
  };
  const initialState: TBackOfficeSearchStore = {
    searchKeyword: '',
    currentSearchType: undefined,
    canSearch: false,
  };

  const backOfficeSearchSlice = createSlice({
    name: 'backOfficeSearch',
    initialState,
    reducers: {
      setSearchKeyword: (state: TBackOfficeSearchStore, action: PayloadAction<string>) => {
        state.searchKeyword = action.payload;
      },
      setCurrentSearchType: (
        state: TBackOfficeSearchStore,
        action: PayloadAction<TBackOfficeSearchTypes | undefined>
      ) => {
        state.currentSearchType = action.payload;
      },

      setCanSearch: (state: TBackOfficeSearchStore, action: PayloadAction<boolean>) => {
        state.canSearch = action.payload;
      },
      reset: () => initialState,
    },
  });

  export const reducer = backOfficeSearchSlice.reducer;
  export const actions = backOfficeSearchSlice.actions;
  export const select = {
    searchKeyword: (state: AppStore.RootState) => state.dbys.backofficeSearch.searchKeyword,
    currentSearchType: (state: AppStore.RootState) => state.dbys.backofficeSearch.currentSearchType,
    canSearch: (state: AppStore.RootState) => state.dbys.backofficeSearch.canSearch,
  };
}

export default BackOfficeSearchStore;
