import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStore from '../../../store';
import TAgentCompanyInfo from '../../models/agent-company-info.type';
import TRequestType from '../../models/request.type';
import TPriorityype from '../../models/priority.type';
import TContactHistory from '../../models/contact-history.type';
import TLastFiveIssue from '../../models/last-five-issue.type';
import AgentOnlineCustomerThunks from '../async-thunks/agent-online-customer.thunks';

namespace AgentOnlineCustomerStore {
  type TAgentOnlineCustomer = {
    customerList: TAgentCompanyInfo[];
    selectedCustomer: TAgentCompanyInfo | undefined;
    ticketTypeList: TRequestType[];
    requestReasonList: TRequestType[];
    subRequestReasonList: TRequestType[];
    priorityList: TPriorityype[];
    stolkholderTypeList: TRequestType[];
    contactHistoryList: TContactHistory[];
    jiraIssueList: TLastFiveIssue | undefined;
  };
  const initialState: TAgentOnlineCustomer = {
    customerList: [],
    selectedCustomer: undefined,
    ticketTypeList: [],
    requestReasonList: [],
    subRequestReasonList: [],
    priorityList: [],
    stolkholderTypeList: [],
    contactHistoryList: [],
    jiraIssueList: undefined,
  };

  const agentOnlineCustomerSlice = createSlice({
    name: 'agentOnlineCustomer',
    initialState,
    reducers: {
      setAgentOnlineCustomerList(state, action: PayloadAction<TAgentCompanyInfo[]>) {
        const customerList = [...action.payload];
        if (customerList.length === 1) {
          state.selectedCustomer = { ...customerList[0] };
        }
        state.customerList = [...customerList];
      },
      setAgentSelectedCustomer(state, action: PayloadAction<TAgentCompanyInfo | undefined>) {
        state.selectedCustomer = action.payload;
      },
      reset() {
        return initialState;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(AgentOnlineCustomerThunks.getCompanyInfoByPhoneNumber.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.customerList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getallcompanyinfo.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.customerList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getCompanyInfoByVKN.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.selectedCustomer = action.payload.data[0];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getTicketTypeList.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.ticketTypeList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getReasonRequest.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.requestReasonList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getSubReasonRequest.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.subRequestReasonList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getPriority.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.priorityList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getStolkholderType.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.stolkholderTypeList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.getContactHistory.fulfilled, (state, action) => {
        if (action.payload) {
          state.contactHistoryList = [...action.payload.data];
        }
      });
      builder.addCase(AgentOnlineCustomerThunks.GetLastFiveIssueByVKN.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.jiraIssueList = action.payload.data;
        }
      });
    },
  });

  export const actions = agentOnlineCustomerSlice.actions;
  export const reducer = agentOnlineCustomerSlice.reducer;
  export const select = {
    agentCompanyInfoList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.customerList,
    agentSelectedCustomer: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.selectedCustomer,
    ticketTypeList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.ticketTypeList,
    requestReasonList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.requestReasonList,
    subRequestReasonList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.subRequestReasonList,
    priorityList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.priorityList,
    stolkholderTypeList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.stolkholderTypeList,
    contactHistoryList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.contactHistoryList,
    jiraIssueList: (state: AppStore.RootState) => state.dbys.agentOnlineCustomer.jiraIssueList,
  };
}

export default AgentOnlineCustomerStore;
